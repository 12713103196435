import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
  Heading,
  HeadingVariant,
  Paragraph,
  ParagraphVariant,
} from '@hungryroot/ramen';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import { Accordion } from '../Accordion/Accordion';

import styles from './HowItWorks.module.css';

type ImageDefinition = {
  src: string;
  height: number;
  width: number;
};

type ResponsiveImageDefinition = {
  mobile: ImageDefinition;
  desktop: ImageDefinition;
};

type Props = {
  tellUsAboutYourselfImage: ResponsiveImageDefinition;
  getYourPersonalizenPlanImage: ResponsiveImageDefinition;
  enjoyHealthyDeliveriesImage: ResponsiveImageDefinition;
};

export function HowItWorks({
  tellUsAboutYourselfImage,
  getYourPersonalizenPlanImage,
  enjoyHealthyDeliveriesImage,
}: Props) {
  const isDesktop = useMedia(BreakpointQueryLarge, false);
  const content = [
    {
      id: 'tell',
      title: '1. Tell us about yourself',
      image: tellUsAboutYourselfImage,
      copy: `Share your preferences, goals and must-have eats, so we can recommend food you'll love.`,
    },
    {
      id: 'personalized',
      title: '2. See our recommendations',
      image: getYourPersonalizenPlanImage,
      copy: `We fill your cart with food we think you’ll love, then you edit to get exactly what you want.`,
    },
    {
      id: 'healthy',
      title: '3. Enjoy healthy deliveries',
      image: enjoyHealthyDeliveriesImage,
      copy: `Get tasty groceries, easy recipes, and essential supplements delivered to your door. Skip or cancel anytime.`,
    },
  ] as const;

  const [active, setActive] = useState<(typeof content)[number]['id']>('tell');

  return (
    <div className={styles.outer}>
      <section className={styles.container}>
        <div className={styles['content-container']}>
          <Heading className={styles.header} variant={HeadingVariant.HeroAlt}>
            Here's how it works:
          </Heading>

          <ul className={styles['accordion-list-container']}>
            {content.map(({ id, title, image, copy }) => {
              const isExpanded = id === active;
              return (
                <li className={styles.item} key={id}>
                  <Accordion
                    isExpanded={isExpanded}
                    onToggle={({ expanded }) => {
                      if (expanded === true) {
                        setActive(id);
                      }
                    }}
                    buttonClassName={styles['accordion-button']}
                    className={styles['accordion-container']}
                    headingClassName={styles['accordion-title']}
                    bodyClassName={styles['accordion-body']}
                    title={
                      <Heading variant={HeadingVariant.H3}>{title}</Heading>
                    }
                  >
                    <figure className={styles.figure}>
                      {isDesktop === false && (
                        <div
                          className={`${styles.image} ${
                            styles[`mobile-image-${id}`]
                          }`}
                        >
                          <img
                            loading="lazy"
                            src={image.mobile.src}
                            height={image.mobile.height}
                            width={image.mobile.width}
                            alt=""
                          />
                        </div>
                      )}
                      <figcaption className={styles.figcaption}>
                        <Paragraph variant={ParagraphVariant.Lead}>
                          {copy}
                        </Paragraph>
                      </figcaption>
                    </figure>
                  </Accordion>
                </li>
              );
            })}
          </ul>
        </div>
        {isDesktop === true && (
          <div>
            {content.map(({ id, image }) => {
              const isActive = active === id;
              const activeClassName =
                isActive === true
                  ? styles['desktop-image-active']
                  : styles['desktop-image'];
              return (
                <div
                  className={`${activeClassName} ${
                    styles[`image-desktop-${id}`]
                  }`}
                  key={id}
                >
                  <img
                    loading="lazy"
                    src={image.desktop.src}
                    height={image.desktop.height}
                    width={image.desktop.width}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
}
